import React from "react";

import { DynamicTrendReportHeroSection } from "@src/lib/services/server/contentful";
import { FadeInContainer } from "@src/components/FadeInContainer";

export type DtrHeroSectionProps = {
  content: DynamicTrendReportHeroSection;
};

const DtrHeroSection = ({ content }: DtrHeroSectionProps) => {
  const {
    title: heroTitle,
    description: heroDescription,
    image: heroImage,
    lottie
  } = content;

  return (
    <FadeInContainer key={content.sys.id}>
      <div className="relative mx-auto mb-16 flex w-full max-w-screen-2xl flex-col gap-6 px-5 text-center lg:mx-24 lg:px-8 2xl:px-24">
        {heroImage ? (
          <div className="relative aspect-video">
            <img
              src={heroImage.url ?? ""}
              alt={heroImage.title ?? ""}
              className="rounded-3xl"
            />
          </div>
        ) : (
          <h1 className="text-6xl font-bold text-pr-dark-gray-600">
            {heroTitle}
          </h1>
        )}
        {heroDescription && <p>{heroDescription}</p>}
      </div>
    </FadeInContainer>
  );
};

export { DtrHeroSection };
