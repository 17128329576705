import {
  DTRDynamicSectionSwiperCollectionItem,
  DTRDynamicSectionSwiperSlidesCollection,
  DTRDynamicSectionSwiperSlidesCollectionItem,
  DynamicTrendReportCardWithIcon,
  DynamicTrendReportImageWithText,
  DynamicTrendReportMediaWithText,
  DynamicTrendReportQuote
} from "@src/lib/services/server/contentful";
import { Document } from "@contentful/rich-text-types";

import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Navigation, Pagination } from "swiper";
import ReactPlayer from "react-player";
import Arrow from "@/img/icons/arrow.svg";

import { RichTextBlock } from "@src/components/RichTextBlock";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

type SlideContentCollection = {
  slideContentCollection: {
    items: DTRDynamicSectionSwiperSlidesCollectionItem[];
  } | null;
};

export type DtrDynamicSwiperProps = {
  id: string;
  swiperIndex: number;
  content: DTRDynamicSectionSwiperCollectionItem;
};

const DtrDynamicSwiper = ({
  id,
  content,
  swiperIndex
}: DtrDynamicSwiperProps) => {
  const { items: swiperSlidesCollection } = content as {
    items: (DTRDynamicSectionSwiperSlidesCollection & SlideContentCollection)[];
  };

  const [currentSlide, setCurrentSlide] = useState(0);

  const formatTextWithLineBreaks = (text: string) =>
    text.includes("\n")
      ? text.split("\n").map((line, i) => <p key={i}>{line}</p>)
      : text;

  const renderMediaWithText = (
    {
      sys,
      image,
      title,
      description,
      vimeoVideo,
      videoThumbnail,
      innerQuote,
      mediaLocation: mediaOnTop
    }: DynamicTrendReportMediaWithText,
    swiperColor: string,
    textColor: string
  ) => {
    const renderMedia = () => {
      if (vimeoVideo) {
        const videoId: string = title
          ? title.toLowerCase().split(" ").join("-")
          : vimeoVideo;

        return (
          <div
            className="relative mx-auto aspect-video"
            key={videoId}
            id={videoId}
          >
            <ReactPlayer
              key={`${videoId}-${currentSlide}`}
              id={`video-${videoId}`}
              url={vimeoVideo}
              controls={true}
              width="100%"
              height="100%"
            />
            <img
              src={videoThumbnail?.url ?? ""}
              alt={title ?? ""}
              className="absolute inset-0 -z-10"
            />
          </div>
        );
      } else if (image) {
        return (
          <div className="relative mx-auto aspect-video">
            <img src={image.url ?? ""} alt={image.title ?? ""} />
          </div>
        );
      }
      return null;
    };

    const renderContent = () => (
      <div className="mx-auto p-6 text-left md:p-12">
        <h3 className="mb-3 text-center text-3xl text-pr-dark-gray-600 md:text-left">
          {title}
        </h3>
        {description && (
          <div className="mb-12 flex flex-col gap-3 text-pr-light-gray-500 last:mb-3">
            {formatTextWithLineBreaks(description)}
          </div>
        )}
        {innerQuote && (
          <div
            className={`text-balance rounded-3xl rounded-bl-none ${swiperColor} ${textColor} p-6 md:p-12`}
          >
            <p className="mb-6 last:mb-0">{innerQuote.quote}</p>
            {innerQuote.author && (
              <div className="flex items-center gap-3">
                {innerQuote.icon && (
                  <img
                    className="rounded-3xl rounded-br-none"
                    src={innerQuote.icon.url ?? ""}
                    alt={innerQuote.icon.title ?? ""}
                    height={50}
                    width={50}
                  />
                )}
                <p>{innerQuote.author}</p>
              </div>
            )}
          </div>
        )}
      </div>
    );

    return (
      <div
        key={sys.id}
        className="overflow-hidden rounded-3xl border border-black lg:mx-16"
      >
        {mediaOnTop ? (
          <>
            {renderMedia()}
            <div className="border-t border-black">{renderContent()}</div>
          </>
        ) : (
          <>
            <div className="border-b border-black">{renderContent()}</div>
            {renderMedia()}
          </>
        )}
      </div>
    );
  };

  const renderImageWithText = ({
    sys,
    image,
    title,
    description,
    mediaLocation: mediaOnLeft
  }: DynamicTrendReportImageWithText) => {
    const renderImage = () => (
      <div className="relative aspect-square max-h-80 basis-1/3 md:max-h-full">
        <img
          src={image?.url ?? ""}
          alt={image?.title ?? ""}
          className="w-full"
        />
      </div>
    );

    const renderContent = () => (
      <div className="basis-2/3 text-left">
        <h3 className="mb-3 text-center text-3xl text-pr-dark-gray-600 md:text-left">
          {title}
        </h3>
        {description && (
          <div className="flex flex-col gap-3 text-pr-light-gray-500 last:mb-3">
            {formatTextWithLineBreaks(description)}
          </div>
        )}
      </div>
    );

    return (
      <div
        key={sys.id}
        className="flex flex-col items-center gap-6 md:flex-row"
      >
        {mediaOnLeft ? (
          <>
            {image && renderImage()}
            {renderContent()}
          </>
        ) : (
          <>
            {renderContent()}
            {image && renderImage()}
          </>
        )}
      </div>
    );
  };

  const renderCardWithIcon = ({
    sys,
    title,
    // text,
    description,
    icon
  }: DynamicTrendReportCardWithIcon) => {
    // const richText = text?.json as Document;
    return (
      <div
        key={sys.id}
        className="flex flex-1 basis-2/5 flex-col items-center gap-3 rounded-3xl border border-black p-6 text-center md:flex-row md:text-left"
      >
        <div className="grow text-pr-dark-gray-600">
          {/* {richText && <RichTextBlock text={richText} />} */}
          {description && (
            <div className="mb-3">{formatTextWithLineBreaks(description)}</div>
          )}
        </div>
        <div className="shrink-0">
          <img
            alt={icon?.title ?? ""}
            src={icon?.url ?? ""}
            height={125}
            width={125}
          />
        </div>
      </div>
    );
  };

  const renderQuote = (
    { sys, quote, author, icon }: DynamicTrendReportQuote,
    swiperColor: string,
    textColor: string
  ) => (
    <div
      key={sys.id}
      className={`w-full text-balance rounded-3xl rounded-bl-none p-6 text-left md:p-12 ${textColor} ${swiperColor}`}
    >
      {quote && <p className="mb-6 last:mb-0">{quote}</p>}
      {icon && (
        <div className="flex items-center justify-end gap-3">
          <img
            className="rounded-3xl rounded-br-none"
            src={icon.url ?? ""}
            alt={icon.title ?? ""}
            height={50}
            width={50}
          />
          <p>{author}</p>
        </div>
      )}
    </div>
  );

  const renderSlideItem = (
    slideItem: DTRDynamicSectionSwiperSlidesCollectionItem,
    index: number,
    backgroundColor: string,
    textColor: string
  ) => {
    switch (slideItem.__typename) {
      case "DynamicTrendReportMediaWithText":
        return renderMediaWithText(slideItem, backgroundColor, textColor);
      case "DynamicTrendReportImageWithText":
        return renderImageWithText(slideItem);
      case "DynamicTrendReportCardWithIcon":
        return renderCardWithIcon(slideItem);
      case "DynamicTrendReportQuote":
        return renderQuote(slideItem, backgroundColor, textColor);
      default:
        return null;
    }
  };

  const renderSwiperInstance = () => {
    const swiperPallete = [
      "bg-pr-green-500", // 0
      "bg-red-600", // 1
      "bg-pr-teal-200", // 2
      "bg-red-600", // 3
      "bg-pr-green-500" // 4
    ];

    const bulletClass = [
      "swiper-pagination-bullet-dynamic-trend-green", // 0
      "swiper-pagination-bullet-dynamic-trend-red", // 1
      "swiper-pagination-bullet-dynamic-trend-teal", // 2
      "swiper-pagination-bullet-dynamic-trend-red", // 3
      "swiper-pagination-bullet-dynamic-trend-green" // 4
    ];

    const bulletActiveClass = [
      "swiper-pagination-bullet-active-dynamic-trend-green", // 0
      "swiper-pagination-bullet-active-dynamic-trend-red", // 1
      "swiper-pagination-bullet-active-dynamic-trend-teal", // 2
      "swiper-pagination-bullet-active-dynamic-trend-red", // 3
      "swiper-pagination-bullet-active-dynamic-trend-green" // 4
    ];

    const getTextColor = (index: number) =>
      ["bg-pr-green-500", "bg-pr-teal-200"].includes(swiperPallete[index])
        ? "text-black"
        : "text-white";

    return (
      <>
        <div className="absolute right-0 top-0 z-10 flex flex-wrap items-center justify-end gap-px">
          <button
            className={`swiper-button-prev-${id} flex items-center justify-center rounded-l-full ${
              swiperPallete[swiperIndex]
            } px-2.5 py-3.5 ${getTextColor(
              swiperIndex
            )} transition md:px-4 md:py-5`}
          >
            <Arrow className="h-3 w-5 rotate-180 fill-current" />
          </button>
          <button
            className={`swiper-button-next-${id} flex items-center justify-center rounded-r-full ${
              swiperPallete[swiperIndex]
            } px-2.5 py-3.5 ${getTextColor(
              swiperIndex
            )} transition md:px-4 md:py-5`}
          >
            <Arrow className="h-3 w-5 fill-current" />
          </button>
        </div>
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          pagination={{
            clickable: true,
            bulletClass: bulletClass[swiperIndex],
            bulletActiveClass: bulletActiveClass[swiperIndex],
            renderBullet: (_, className) => `<span class="${className}"></span>`
          }}
          navigation={{
            prevEl: `.swiper-button-prev-${id}`,
            nextEl: `.swiper-button-next-${id}`
          }}
          className="dynamic-trend-swiper swiper-pagination-top"
          spaceBetween={16}
          slidesPerView={1}
          autoHeight={true}
          onSlideChange={swiper => setCurrentSlide(swiper.activeIndex)}
          simulateTouch={false}
          loop={true}
        >
          {swiperSlidesCollection.map((slides, index) => (
            <SwiperSlide
              key={`slide-${index}`}
              className="flex flex-col flex-wrap gap-4 pt-20 md:flex-row"
            >
              {slides.slideContentCollection?.items.map(
                (
                  slideItem: DTRDynamicSectionSwiperSlidesCollectionItem,
                  index: number
                ) =>
                  renderSlideItem(
                    slideItem,
                    index,
                    swiperPallete[swiperIndex],
                    getTextColor(swiperIndex)
                  )
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </>
    );
  };

  return (
    <div className="mx-auto max-w-screen-xl px-5">
      <div className="relative">{renderSwiperInstance()}</div>
    </div>
  );
};

export { DtrDynamicSwiper };
